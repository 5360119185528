import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationPaths } from "../constants/constants";

import ProductShowcase from "../containers/ProductShowcase";
import CheckoutDetails from "../containers/CheckoutDetails";
import PaymentDetails from "../containers/PaymentDetails";
import PaymentGateway from "../containers/PaymentGateway";
import OrderSuccess from "../containers/OrderSuccess";
import OrderFailure from "../containers/OrderFailure";

const Navigator = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={ProductShowcase} />
        <Route
          exact
          path={NavigationPaths.BILLINGDETAILSPAGE}
          component={CheckoutDetails}
        />
        <Route
          exact
          path={NavigationPaths.PAYMENTDETAILSPAGE}
          component={PaymentDetails}
        />
        <Route
          exact
          path={NavigationPaths.PAYMENTGATEWAYPAGE}
          component={PaymentGateway}
        />
        <Route
          exact
          path={NavigationPaths.PAYMENTSUCCESSPAGE}
          component={OrderSuccess}
        />
        <Route
          exact
          path={NavigationPaths.PAYMENTFAILPAGE}
          component={OrderFailure}
        />
      </Switch>
    </Router>
  );
};

export default Navigator;
