import axios from "axios";
import { BASE_API_URL } from "../constants/constants";
import { get } from "lodash";

let axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 60000,
  headers: {
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (responseData) => {
    console.log("+++++++++", "Interceptor Output Starts", "+++++++++");
    console.log(responseData.data);
    console.log("+++++++++", "Interceptor Output Ends", "+++++++++");

    return responseData.data;
  },
  (errorData) => {
    const errorResponse = get(errorData, "response.data");

    if (!errorResponse) {
      return Promise.reject({
        message:
          "ERROR: Network Connection Failure. Please Check Your Internet Access and Try Again.",
      });
    }

    console.log("+++++++++", "Interceptor Output Starts", "+++++++++");
    console.log(errorResponse);
    console.log("+++++++++", "Interceptor Output Ends", "+++++++++");

    return Promise.reject(errorResponse);
  }
);

export default axiosInstance;
