import axiosInstance from "./index";
import * as Constants from "../constants/constants";

export const getBooks = () => {
  return axiosInstance.get(Constants.GET_BOOKS);
};

export const checkout = (dataPayload) => {
  return axiosInstance.post(Constants.CHECKOUT, dataPayload);
};

export const verifyPayment = (dataPayload) => {
  return axiosInstance.post(Constants.VERIFY_PAYMENT, dataPayload);
};

export const failPayment = (dataPayload) => {
  return axiosInstance.post(Constants.FAIL_PAYMENT, dataPayload);
};
