import { ReduxConstants } from "../constants/constants";

export default (
  state = {
    createdOrder: {},
  },
  action
) => {
  switch (action.type) {
    case ReduxConstants.ADDORDER:
      return {
        ...state,
        createdOrder: action.payload,
      };

    default: {
      return state;
    }
  }
};
