import { ReduxConstants } from '../constants/constants';

export default (
  state = {
    books: [],
    ebooks: [],
    giftCards: [],
    billingDetails: {},
    shippingDetails: {},
    giftCardUpdated: false,
  },
  action
) => {
  switch (action.type) {
    case ReduxConstants.ADDGIFTCARD:
      return {
        ...state,
        giftCards: [...state.giftCards, action.payload],
      };

    case ReduxConstants.REMOVEGIFTCARD:
      let giftCards = Object.assign([], state.giftCards);
      const updatedGiftCards = giftCards.filter(
        (giftCard, giftCardIndex) =>
          giftCardIndex !== action.payload.giftCardIndex
      );
      return {
        ...state,
        giftCards: updatedGiftCards,
      };

    case ReduxConstants.UPDATEGIFTCARD:
      return {
        ...state,
        giftCards: action.payload,
        giftCardUpdated: true,
      };

    case ReduxConstants.UPDATEGIFTCARDFLAG:
      return {
        ...state,
        giftCardUpdated: false,
      };

    case ReduxConstants.ADDBOOK:
      let existingBooks = Object.assign([], state.books);
      existingBooks.push(action.payload);
      return {
        ...state,
        books: existingBooks,
      };

    case ReduxConstants.REMOVEBOOK:
      let books = Object.assign([], state.books);
      const updatedBooks = books.filter(
        (book, bookIndex) => bookIndex !== action.payload.bookIndex
      );
      return {
        ...state,
        books: updatedBooks,
      };

    case ReduxConstants.ADDEBOOK:
      let existingEBooks = Object.assign([], state.ebooks);
      existingEBooks.push(action.payload);
      return {
        ...state,
        ebooks: existingEBooks,
      };

    case ReduxConstants.REMOVEEBOOK:
      let ebooks = Object.assign([], state.ebooks);
      const updatedEBooks = ebooks.filter(
        (ebook, ebookIndex) => ebookIndex !== action.payload.ebookIndex
      );
      return {
        ...state,
        ebooks: updatedEBooks,
      };

    case ReduxConstants.ADDBILLINGDETAILS:
      return {
        ...state,
        billingDetails: action.payload,
      };

    case ReduxConstants.ADDSHIPPINGDETAILS:
      return {
        ...state,
        shippingDetails: action.payload,
      };

    case ReduxConstants.RESETCART:
      return {
        ...state,
        books: [],
        ebooks: [],
        giftCards: [],
        billingDetails: {},
        shippingDetails: {},
        giftCardUpdated: false,
      };

    default: {
      return state;
    }
  }
};
