import React from 'react';
import { connect } from 'react-redux';
import { set, get } from 'lodash';
import { updateGiftCard } from '../../actions/cartActions';

import './styles.scss';

class Cart extends React.Component {
  handleUpdateGiftCard = (value, index) => {
    const giftCards = get(this.props.cart, 'giftCards', []);
    set(giftCards, `[${index}].physical`, value);
    this.props.updateGiftCard(giftCards);
  };

  getTotal = () => {
    let amount = 0,
      giftCardAmount = 0,
      bookAmount = 0,
      ebookAmount = 0,
      tax = 0,
      total = 0;

    const cartBooks = get(this.props.cart, 'books', []);
    const cartEBooks = get(this.props.cart, 'ebooks', []);
    const giftCards = get(this.props.cart, 'giftCards', []);

    if (cartBooks && cartBooks.length) {
      for (let cb of cartBooks) {
        bookAmount += get(cb, 'prices.INR', 0);
      }
      tax += 0.12 * bookAmount;
    }

    if (cartEBooks && cartEBooks.length) {
      for (let ceb of cartEBooks) {
        ebookAmount += get(ceb, 'prices.INR', 0);
      }
      tax += 0.18 * ebookAmount;
    }

    if (giftCards && giftCards.length) {
      for (let gc of giftCards) {
        giftCardAmount += get(gc, 'value', 0);
      }
      tax += 0.18 * giftCardAmount;
    }

    amount = giftCardAmount + bookAmount + ebookAmount;
    total = amount + tax;

    return {
      amount,
      tax,
      total: Math.ceil(total),
      rounded: Math.ceil(total) - total,
    };
  };

  render() {
    const cartBooks = get(this.props.cart, 'books', []);
    const cartEBooks = get(this.props.cart, 'ebooks', []);
    const giftCards = get(this.props.cart, 'giftCards', []);
    const breakdown = this.getTotal();

    return (
      <div className="content-cart">
        <div className="content-cart-header">
          <h4 className="content-cart-title">Your cart</h4>
        </div>

        <div className="content-cart-content d-flex justify-content-start flex-column">
          {giftCards && giftCards.length !== 0 && (
            <div className="cart-subscriptions">
              <h4 className="cart-subscriptions-header">Gift Cards</h4>

              {giftCards.map((giftCard, index) => {
                return (
                  <div key={index} className="cart-subscriptions-item">
                    <h5 className="cart-subscriptions-title">{giftCard.title}</h5>
                    <h6 className="cart-subscriptions-cost">
                      <small>Rs.</small> {giftCard.sellingPrice}{' '}
                      <span className="original-cost">{giftCard.actualPrice}</span>
                    </h6>
                    <label
                      className="cart-subscriptions-physical"
                      title="Add a physical Gift Card which is shipped to you a no extra cost!"
                    >
                      <input
                        type="checkbox"
                        className="cart-subscriptions-input"
                        checked={giftCard.physical}
                        value={giftCard.physical}
                        onChange={(event) =>
                          this.handleUpdateGiftCard(event.currentTarget.checked, index)
                        }
                      />
                      Add On: Need physical Gift Card
                    </label>
                  </div>
                );
              })}
            </div>
          )}

          {cartBooks && cartBooks.length !== 0 && (
            <div className="cart-books">
              <h4 className="cart-books-header">Books</h4>

              {cartBooks.map((book, index) => {
                return (
                  <div key={index} className="cart-books-item">
                    <h5 className="cart-books-title">{book.title}</h5>
                    <h6 className="cart-books-cost">
                      <small>Rs.</small> {get(book, 'prices.INR', 'N/A')}
                    </h6>
                  </div>
                );
              })}
            </div>
          )}

          {cartEBooks && cartEBooks.length !== 0 && (
            <div className="cart-ebooks">
              <h4 className="cart-ebooks-header">E-Books</h4>

              {cartEBooks.map((ebook, index) => {
                return (
                  <div key={index} className="cart-ebooks-item">
                    <h5 className="cart-ebooks-title">{ebook.title}</h5>
                    <h6 className="cart-ebooks-cost">
                      <small>Rs.</small> {get(ebook, 'prices.INR', 'N/A')}
                    </h6>
                  </div>
                );
              })}
            </div>
          )}

          {(giftCards.length !== 0 || cartBooks.length !== 0 || cartEBooks.length !== 0) && (
            <div className="cart-summary">
              <div className="cart-total">
                <h4 className="cart-total-header">Price Breakdown</h4>

                <div className="cart-total-item">
                  <h5 className="cart-total-title">Amount</h5>
                  <h6 className="cart-total-cost">
                    <small>Rs.</small> {breakdown.amount}
                  </h6>
                </div>

                <div className="cart-total-item">
                  <h5 className="cart-total-title">Tax</h5>
                  <h6 className="cart-total-cost">
                    <small>Rs.</small> {breakdown.tax.toFixed(2)}
                  </h6>
                </div>

                <div className="cart-total-item">
                  <h5 className="cart-total-title">Rounded Off</h5>
                  <h6 className="cart-total-cost">
                    <small>(+/-) Rs.</small> {breakdown.rounded.toFixed(2)}
                  </h6>
                </div>

                <div className="cart-total-item">
                  <h5 className="cart-total-title">Total</h5>
                  <h6 className="cart-total-cost">
                    <small>Rs.</small> {breakdown.total}
                  </h6>
                </div>
              </div>

              <div className="cart-checkout">
                <button
                  type="button"
                  className="button-primary"
                  onClick={() => this.props.handleNavigation()}
                >
                  {this.props.checkoutButtonText}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  updateGiftCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
