import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { RAZORPAY_SDK_URL, PaymentGateways, NavigationPaths } from '../../constants/constants';
import { checkoutOrder, verifyOrder, failOrder } from '../../common/helpers';
import { createOrder } from '../../actions/orderActions';

import Cart from '../../components/Cart';
import PageLoader from '../../components/PageLoader';

import styles from './styles.scss';

class PaymentGateway extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: true,
      selectedPaymentGateway: null,
    };

    this.razorpayInstance = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const script = document.createElement('script');
    script.src = RAZORPAY_SDK_URL;
    script.async = true;
    script.onload = () => this.razorpayScriptLoaded();

    document.head.appendChild(script);
  }

  razorpayScriptLoaded = () => {
    this.setState({ pageLoader: false });
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      [fieldName]: fieldValue,
    }));
  };

  handleCheckout = () => {
    const { selectedPaymentGateway } = this.state;
    if (!selectedPaymentGateway) {
      return;
    }

    this.setState({ pageLoader: true });

    const books = get(this.props.cart, 'books', []);
    const ebooks = get(this.props.cart, 'ebooks', []);
    const giftCards = get(this.props.cart, 'giftCards', []);
    const billingDetails = get(this.props.cart, 'billingDetails', {});
    const shippingDetails = get(this.props.cart, 'shippingDetails', {});

    checkoutOrder({
      books,
      ebooks,
      giftCards,
      billingDetails,
      shippingDetails,
      gateway: selectedPaymentGateway,
    })
      .then((responseData) => {
        this.setState({ pageLoader: false });
        this.props.createOrder(responseData.data);

        if (selectedPaymentGateway === PaymentGateways.CASHFREE) {
          this.props.history.push(NavigationPaths.PAYMENTDETAILSPAGE);
        } else {
          this.triggerRazorpayCheckout(responseData.data);
        }
      })
      .catch((errorData) => {
        this.setState({ pageLoader: false });
        alert('Something went wrong. Please try again later.');
      });
  };

  triggerRazorpayCheckout = (orderDetails) => {
    this.razorpayInstance = new window.Razorpay({
      theme: {
        color: '#19b80f',
      },
      name: 'Wise Owl Learning',
      handler: (response) => {
        this.verifyOrder(response);
      },
      key: get(orderDetails, 'appId'),
      prefill: {
        name: get(orderDetails, 'customerName'),
        email: get(orderDetails, 'customerEmail'),
        contact: get(orderDetails, 'customerPhone'),
      },
      order_id: get(orderDetails, 'razorpayOrder.id'),
      amount: get(orderDetails, 'razorpayOrder.amount'),
      currency: get(orderDetails, 'razorpayOrder.currency'),
      description: get(orderDetails, 'razorpayOrder.receipt'),
      modal: {
        ondismiss: () => this.failOrder(),
      },
    });

    this.razorpayInstance.open();
  };

  verifyOrder = (paymentData) => {
    if (paymentData && paymentData.razorpay_payment_id && paymentData.razorpay_signature) {
      this.setState({ pageLoader: true });

      verifyOrder(get(this.props.order, 'createdOrder._id'), paymentData.razorpay_payment_id)
        .then((responseData) => {
          this.setState({ pageLoader: false });
          this.props.history.push(NavigationPaths.PAYMENTSUCCESSPAGE);
        })
        .catch((errorData) => {
          console.log(errorData);
          this.setState({ pageLoader: false });
          alert('Something went wrong. Please try again later.');
        });
    }
  };

  failOrder = () => {
    this.setState({ pageLoader: true });

    failOrder(get(this.props.order, 'createdOrder._id'))
      .then((responseData) => {
        this.setState({ pageLoader: false });
        this.props.history.push(NavigationPaths.PAYMENTFAILPAGE);
      })
      .catch((errorData) => {
        console.log(errorData);
        this.setState({ pageLoader: false });
        alert('Something went wrong. Please try again later.');
      });
  };

  render() {
    const { pageLoader, selectedPaymentGateway } = this.state;

    return (
      <div className="container-wrapper" style={styles}>
        <div className="container-wrapper-content">
          <div className="content-header d-flex align-items-center">
            <a href="https://www.wiseowllearning.io">
              <img
                src={require('../../assets/images/logos/owl-logo.png')}
                className="content-header-logo"
                alt="Wise Owl Learning"
              />
            </a>

            <img
              src={require('../../assets/images/logos/skip-logo.png')}
              className="content-header-logo content-header-logosquare"
              alt="Wise Owl Learning"
            />
          </div>

          <div className="content-body">
            <div className="content-section">
              <div className="content-body-header">
                <h2 className="content-body-title">Payment</h2>
                <p className="content-body-description">Select a payment gateway to continue.</p>
              </div>

              <div className="content-body-wrapper">
                <div className="content-form">
                  <h4 className="content-form-header">Payment Gateways</h4>

                  <div className="payment-gateway">
                    <button
                      className={`payment-gateway-item ${
                        selectedPaymentGateway === PaymentGateways.RAZORPAY &&
                        'payment-gateway-item-active'
                      }`}
                      onClick={() =>
                        this.handleFieldUpdate('selectedPaymentGateway', PaymentGateways.RAZORPAY)
                      }
                    >
                      Pay via ICICI Bank Cards
                      <br />
                      <small>(Additional 10% Bank Discount)</small>
                    </button>

                    <button
                      className={`payment-gateway-item ${
                        selectedPaymentGateway === PaymentGateways.CASHFREE &&
                        'payment-gateway-item-active'
                      }`}
                      onClick={() =>
                        this.handleFieldUpdate('selectedPaymentGateway', PaymentGateways.CASHFREE)
                      }
                    >
                      Pay via Other Bank Credit / Debit Cards, <br />
                      NetBanking or UPI
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Cart handleNavigation={this.handleCheckout} checkoutButtonText={'Continue'} />
          </div>

          <div className="content-footer d-flex align-items-center justify-content-center">
            <p className="content-footer-copyright">
              &copy; Copyright 2020 - Wise Owl Learning Private Limited
              <br />
              All Rights Reserved
            </p>
          </div>
        </div>

        {pageLoader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  order: state.order,
});

const mapDispatchToProps = {
  createOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateway);
