import React from 'react';

import { CashfreePaymentMethods } from '../../constants/constants';

import styles from './styles.scss';

const PaymentMethods = (props) => {
  return (
    <div className="payment-methods" style={styles}>
      <button
        className={`payment-methods-item ${
          props.selectedPaymentMethod === CashfreePaymentMethods.CARD &&
          'payment-methods-item-active'
        }`}
        onClick={() => props.updateSelectedMethod(CashfreePaymentMethods.CARD)}
      >
        Credit Card / Debit Card
      </button>

      {/* <button
        className={`payment-methods-item ${
          props.selectedPaymentMethod === CashfreePaymentMethods.NETBANKING &&
          'payment-methods-item-active'
        }`}
        onClick={() => props.updateSelectedMethod(CashfreePaymentMethods.NETBANKING)}
      >
        Net Banking
      </button> */}

      <button
        className={`payment-methods-item ${
          props.selectedPaymentMethod === CashfreePaymentMethods.WALLET &&
          'payment-methods-item-active'
        }`}
        onClick={() => props.updateSelectedMethod(CashfreePaymentMethods.WALLET)}
      >
        Wallet
      </button>

      <button
        className={`payment-methods-item ${
          props.selectedPaymentMethod === CashfreePaymentMethods.UPI &&
          'payment-methods-item-active'
        }`}
        onClick={() => props.updateSelectedMethod(CashfreePaymentMethods.UPI)}
      >
        UPI
      </button>
    </div>
  );
};

export default PaymentMethods;
