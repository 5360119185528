import React from 'react';

import Loader from '../../assets/images/icons/loader.svg';

class PageLoader extends React.Component {
  componentDidMount() {
    document.body.style.overflowY = 'hidden';
  }

  render() {
    return (
      <div className="page-loader d-flex align-items-center justify-content-center">
        <div className="flex-wrapper text-center">
          <img src={Loader} className="page-loader-asset" alt="Loading" />
          <h5 className="page-loader-text">Loading. Please wait...</h5>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
  }
}

export default PageLoader;
