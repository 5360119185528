import {
  checkout,
  // verifyPayment,
  // failPayment,
} from "../../services/productService";

export default class DataManager {
  checkout = (books, ebooks, giftCards, billingDetails, shippingDetails) => {
    const dataPayload = {
      products: [...books, ...giftCards, ...ebooks],
      billingDetails,
      shippingDetails: {},
    };

    let setShippingDetails = false;
    if (books && books.length) {
      setShippingDetails = true;
    } else if (giftCards && giftCards.length) {
      for (let giftCard of giftCards) {
        if (giftCard.physical && !setShippingDetails) {
          setShippingDetails = true;
        }
      }
    }

    if (setShippingDetails) {
      dataPayload.shippingDetails = shippingDetails;
    }

    return checkout(dataPayload);
  };
}
