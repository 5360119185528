import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  CASHFREE_SDK_URL,
  CashfreeWalletCodes,
  CASHFREE_ENVIRONMENT,
  CashfreePaymentMethods,
  // CashfreeNetBankingCodes,
  NavigationPaths,
} from '../../constants/constants';
import { verifyOrder, failOrder } from '../../common/helpers';

import DataManager from './dataManager';
import Cart from '../../components/Cart';
import PageLoader from '../../components/PageLoader';
import PaymentMethods from '../../components/PaymentMethods';

import styles from './styles.scss';

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: false,
      payByCard: false,
      payByNetBanking: false,
      PayByWallet: false,
      payByUPI: false,
      formData: {},
      selectedPaymentMethod: null,
    };

    this.dataManager = new DataManager();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const script = document.createElement('script');
    script.src = CASHFREE_SDK_URL;
    script.async = true;
    script.onload = () => this.cashfreeScriptLoaded();
    document.head.appendChild(script);
  }

  cashfreeScriptLoaded = () => {
    window.CashFree.init({
      layout: {},
      checkout: 'transparent',
      mode: CASHFREE_ENVIRONMENT,
    });
  };

  handleFieldUpdate = (fieldName, fieldValue, fieldKey) => {
    this.setState((state) => ({
      ...state,
      [fieldKey]: {
        ...state[fieldKey],
        [fieldName]: fieldValue,
      },
    }));
  };

  handleSelectedPaymentMethod = (fieldValue) => {
    let formData = {};
    const { selectedPaymentMethod } = this.state;
    if (selectedPaymentMethod === fieldValue) {
      return;
    }

    switch (fieldValue) {
      case CashfreePaymentMethods.CARD:
        formData = {
          cvv: '',
          number: '',
          holder: '',
          expiryYear: '',
          expiryMonth: '',
        };
        break;

      case CashfreePaymentMethods.WALLET:
      case CashfreePaymentMethods.NETBANKING:
        formData = {
          code: '',
        };
        break;

      case CashfreePaymentMethods.UPI:
        formData = {
          vpa: '',
        };
        break;

      default:
        return;
    }

    this.setState((state) => ({
      ...state,
      formData,
      selectedPaymentMethod: fieldValue,
    }));
  };

  postPaymentCallback = (event) => {
    console.log(event);

    if (event.name === 'PAYMENT_RESPONSE' && event.response) {
      if (event.response.txStatus === 'SUCCESS') {
        // Handle Success
        this.verifyOrder();
      } else if (
        event.response.txStatus === 'CANCELLED' ||
        event.response.txStatus === 'FAILED'
      ) {
        // Handle Cancelled
        this.failOrder();
      }
    } else if (event.name === 'VALIDATION_ERROR') {
      // Incorrect inputs
      this.failOrder();
    }
  };

  formValidation = () => {
    const numberRegex = /^[0-9]*$/;

    let isFormValid = true;
    const { selectedPaymentMethod, formData } = this.state;

    switch (selectedPaymentMethod) {
      case CashfreePaymentMethods.CARD:
        if (
          !formData.cvv ||
          !formData.holder ||
          !formData.number ||
          !formData.expiryMonth ||
          !formData.expiryYear
        ) {
          isFormValid = false;
          alert('Card details are required.');
          return isFormValid;
        }

        if (
          !numberRegex.test(formData.cvv) ||
          !numberRegex.test(formData.expiryMonth) ||
          !numberRegex.test(formData.expiryYear) ||
          !numberRegex.test(formData.number)
        ) {
          isFormValid = false;
          alert('Invalid Card details entered.');
          return isFormValid;
        }

        break;

      case CashfreePaymentMethods.WALLET:
      case CashfreePaymentMethods.NETBANKING:
        if (!formData.code) {
          isFormValid = false;
          alert('Please select from the available options.');
          return isFormValid;
        }

        break;

      case CashfreePaymentMethods.UPI:
        if (!formData.vpa) {
          isFormValid = false;
          alert('UPI Handle is required.');
          return isFormValid;
        }

        break;

      default:
        return;
    }

    return isFormValid;
  };

  handleCheckout = () => {
    const isFormValid = this.formValidation();
    if (!isFormValid) {
      return;
    }

    const { selectedPaymentMethod, formData } = this.state;
    let orderDetails = Object.assign(
      {},
      get(this.props.order, 'createdOrder', {})
    );

    switch (selectedPaymentMethod) {
      case CashfreePaymentMethods.CARD:
        orderDetails.paymentOption = CashfreePaymentMethods.CARD.toLowerCase();
        orderDetails.card = Object.assign({}, formData);
        break;

      case CashfreePaymentMethods.NETBANKING:
        orderDetails.paymentOption = CashfreePaymentMethods.NETBANKING.toLowerCase();
        orderDetails.nb = Object.assign({}, formData);
        break;

      case CashfreePaymentMethods.WALLET:
        orderDetails.paymentOption = CashfreePaymentMethods.WALLET.toLowerCase();
        orderDetails.wallet = Object.assign({}, formData);
        break;

      case CashfreePaymentMethods.UPI:
        orderDetails.paymentOption = CashfreePaymentMethods.UPI.toLowerCase();
        orderDetails.upi = Object.assign({}, formData);
        break;

      default:
        return;
    }

    window.CashFree.initPopup();
    window.CashFree.paySeamless(orderDetails, this.postPaymentCallback);
  };

  verifyOrder = () => {
    this.setState({ pageLoader: true });

    verifyOrder(get(this.props.order, 'createdOrder._id'))
      .then((responseData) => {
        this.setState({ pageLoader: false });
        this.props.history.replace(NavigationPaths.PAYMENTSUCCESSPAGE);
      })
      .catch((errorData) => {
        console.log(errorData);
        this.setState({ pageLoader: false });
        alert('Something went wrong. Please try again later.');
      });
  };

  failOrder = () => {
    this.setState({ pageLoader: true });

    failOrder(get(this.props.order, 'createdOrder._id'))
      .then((responseData) => {
        this.setState({ pageLoader: false });
        this.props.history.replace(NavigationPaths.PAYMENTFAILPAGE);
      })
      .catch((errorData) => {
        console.log(errorData);
        this.setState({ pageLoader: false });
        alert('Something went wrong. Please try again later.');
        window.location.reload()
      });
  };

  render() {
    const { pageLoader, selectedPaymentMethod, formData } = this.state;

    return (
      <div className="container-wrapper" style={styles}>
        <div className="container-wrapper-content">
          <div className="content-header d-flex align-items-center">
            <a href="https://www.wiseowllearning.io">
              <img
                src={require('../../assets/images/logos/owl-logo.png')}
                className="content-header-logo"
                alt="Wise Owl Learning"
              />
            </a>

            <img
              src={require('../../assets/images/logos/skip-logo.png')}
              className="content-header-logo content-header-logosquare"
              alt="Wise Owl Learning"
            />
          </div>

          <div className="content-body">
            <div className="content-section">
              <div className="content-body-header">
                <h2 className="content-body-title">Payment</h2>
                <p className="content-body-description">
                  Select a payment method and enter your payment details to
                  continue.
                </p>
              </div>

              <div className="content-body-wrapper">
                <div className="content-form">
                  <h4 className="content-form-header">Payment Methods</h4>

                  <PaymentMethods
                    selectedPaymentMethod={selectedPaymentMethod}
                    updateSelectedMethod={this.handleSelectedPaymentMethod}
                  />
                </div>

                {selectedPaymentMethod &&
                  (selectedPaymentMethod === CashfreePaymentMethods.CARD ? (
                    <div className="payment-information">
                      <div className="row">
                        <div className="col-12">
                          <h6 className="payment-information-title">
                            Card Details
                          </h6>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label htmlFor="holder" className="input-label">
                              Card Holder
                            </label>
                            <input
                              type="text"
                              id="holder"
                              placeholder="Card Holder"
                              className="input-field"
                              value={formData.holder}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'holder',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label htmlFor="number" className="input-label">
                              Card Number
                            </label>
                            <input
                              type="text"
                              id="number"
                              placeholder="Card Number"
                              className="input-field"
                              value={formData.number}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'number',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label
                              htmlFor="expiryMonth"
                              className="input-label"
                            >
                              Expiry Month
                            </label>
                            <input
                              type="text"
                              id="expiryMonth"
                              placeholder="Expiry Month (MM)"
                              className="input-field"
                              value={formData.expiryMonth}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'expiryMonth',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label htmlFor="expiryYear" className="input-label">
                              Expiry Year
                            </label>
                            <input
                              type="text"
                              id="expiryYear"
                              placeholder="Expiry Year (YYYY)"
                              className="input-field"
                              value={formData.expiryYear}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'expiryYear',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label htmlFor="cvv" className="input-label">
                              CVV
                            </label>
                            <input
                              type="text"
                              id="cvv"
                              placeholder="CVV"
                              className="input-field"
                              value={formData.cvv}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'cvv',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : // : selectedPaymentMethod === CashfreePaymentMethods.NETBANKING ? (
                  //   <div className="payment-information">
                  //     <div className="row">
                  //       <div className="col-12">
                  //         <h6 className="payment-information-title">Net Banking Details</h6>
                  //       </div>
                  //     </div>

                  //     <div className="row">
                  //       <div className="col-12 col-sm-6">
                  //         <div className="input-wrapper">
                  //           <label htmlFor="code" className="input-label">
                  //             Bank Name
                  //           </label>
                  //           <select
                  //             type="select"
                  //             id="code"
                  //             className="input-select-fw"
                  //             value={formData.code}
                  //             onChange={(event) =>
                  //               this.handleFieldUpdate('code', event.target.value, 'formData')
                  //             }
                  //           >
                  //             <option value="" disabled>
                  //               Please choose a Bank
                  //             </option>

                  //             {CashfreeNetBankingCodes.map((bankCode, index) => {
                  //               return (
                  //                 <option key={index} value={bankCode.value}>
                  //                   {bankCode.name}
                  //                 </option>
                  //               );
                  //             })}
                  //           </select>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // )
                  selectedPaymentMethod === CashfreePaymentMethods.WALLET ? (
                    <div className="payment-information">
                      <div className="row">
                        <div className="col-12">
                          <h6 className="payment-information-title">
                            Wallet Details
                          </h6>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label htmlFor="code" className="input-label">
                              Wallet
                            </label>
                            <select
                              type="select"
                              id="code"
                              className="input-select-fw"
                              value={formData.code}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'code',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            >
                              <option value="" disabled>
                                Please choose a Wallet
                              </option>
                              {CashfreeWalletCodes.map((walletCode, index) => {
                                return (
                                  <option key={index} value={walletCode.value}>
                                    {walletCode.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="payment-information">
                      <div className="row">
                        <div className="col-12">
                          <h6 className="payment-information-title">
                            UPI Details
                          </h6>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="input-wrapper">
                            <label htmlFor="vpa" className="input-label">
                              Virtual Payment Address (VPA)
                            </label>
                            <input
                              type="text"
                              id="vpa"
                              placeholder="VPA Address"
                              className="input-field"
                              value={formData.vpa}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  'vpa',
                                  event.target.value,
                                  'formData'
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <Cart
              handleNavigation={this.handleCheckout}
              checkoutButtonText={'Pay Now'}
            />
          </div>

          <div className="content-footer d-flex align-items-center justify-content-center">
            <p className="content-footer-copyright">
              &copy; Copyright 2020 - Wise Owl Learning Private Limited
              <br />
              All Rights Reserved
            </p>
          </div>
        </div>

        {pageLoader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  order: state.order,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
