import React from 'react';
import Navigator from './navigator';
import configureStore from './store/CreateStore';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'bootstrap/dist/css/bootstrap.min.css';

import './styles.scss';

const persistStore = configureStore();
const { reduxStore, persistedStore } = persistStore;

export default class App extends React.Component {
  render() {
    return (
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistedStore}>
          <Navigator />
        </PersistGate>
      </Provider>
    );
  }
}
