import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.scss';

class OrderFailure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container-wrapper container-wrapper--nopad">
        <div className="container-wrapper-content">
          <div className="content-header d-flex align-items-center">
            <a href="https://www.wiseowllearning.io">
              <img
                src={require('../../assets/images/logos/owl-logo.png')}
                className="content-header-logo"
                alt="Wise Owl Learning"
              />
            </a>

            <img
              src={require('../../assets/images/logos/skip-logo.png')}
              className="content-header-logo content-header-logosquare"
              alt="Wise Owl Learning"
            />
          </div>

          <div className="content-body content-body--order">
            <div className="content-section">
              <div className="content-body-header">
                <h2 className="content-body-title">Sorry!</h2>
                <p className="content-body-description">
                  Your order with ID {get(this.props.order, 'createdOrder.orderId', 'N/A')} has
                  failed.
                </p>

                <Link to={'/'} replace={true} className="content-body-link">
                  Go to Homepage
                </Link>
              </div>
            </div>
          </div>

          <div className="content-footer d-flex align-items-center justify-content-center">
            <p className="content-footer-copyright">
              &copy; Copyright 2020 - Wise Owl Learning Private Limited
              <br />
              All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderFailure);
