import { getBooks } from '../../services/productService';

export default class DataManager {
  getBooks = () => {
    return getBooks();
  };
}

export const giftCards = [
  {
    title: '1 Subject',
    actualPrice: 2000,
    sellingPrice: 1500,
    key: 'oneSubject',
  },
  {
    title: '2 Subjects',
    actualPrice: 4000,
    sellingPrice: 3000,
    key: 'twoSubject',
  },
  {
    title: 'All 3 Subjects',
    actualPrice: 5400,
    sellingPrice: 4000,
    key: 'threeSubject',
  },
];
