import React from 'react';

import { get, isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import {
  addBillingDetails,
  addShippingDetails,
  updateGiftCardFlag,
} from '../../actions/cartActions';
import { NavigationPaths } from '../../constants/constants';

import DataManager from './dataManager';
import Cart from '../../components/Cart';
import PageLoader from '../../components/PageLoader';
class CheckoutDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: false,
      checkoutData: null,
      showShippingDetails: false,
      billingDetails: {
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '91',
        mobileNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
      },
      shippingDetails: {
        firstName: '',
        lastName: '',
        countryCode: '91',
        mobileNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
      },
    };

    this.dataManager = new DataManager();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.prefillBillingDetails();
    this.prefillShippingDetails();
    this.shouldShowShippingDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.cart.giftCardUpdated, this.props.cart.giftCardUpdated)) {
      this.shouldShowShippingDetails();
    }
  }

  prefillBillingDetails = () => {
    const billingDetails = get(this.props.cart, 'billingDetails', {});
    if (billingDetails && typeof billingDetails === 'object' && !isEmpty(billingDetails)) {
      this.setState({ billingDetails });
    }
  };

  prefillShippingDetails = () => {
    const shippingDetails = get(this.props.cart, 'shippingDetails', {});
    if (shippingDetails && typeof shippingDetails === 'object' && !isEmpty(shippingDetails)) {
      this.setState({ shippingDetails });
    }
  };

  setShowShippingDetails = (value) => {
    this.setState((state) => ({
      ...state,
      showShippingDetails: value,
    }));
  };

  handleFieldUpdate = (fieldName, fieldValue, fieldKey) => {
    this.setState((state) => ({
      ...state,
      [fieldKey]: {
        ...state[fieldKey],
        [fieldName]: fieldValue,
      },
    }));
  };

  validateFormDetails = () => {
    const { billingDetails, shippingDetails, showShippingDetails } = this.state;

    for (let key of Object.keys(billingDetails)) {
      if (!billingDetails[key]) {
        alert('Error: All fields in Billing information are required.');

        return false;
      }
    }

    if (!/.+@.+\..+/.test(billingDetails.email)) {
      alert('Error: Email address prpvided under Billing information is incorrect.');

      return false;
    }
    if (!/^[0-9]*$/.test(billingDetails.mobileNumber)) {
      alert('Error: Mobile number provided in Billing details in incorrect.');

      return false;
    }

    if (showShippingDetails) {
      for (let key of Object.keys(shippingDetails)) {
        if (!shippingDetails[key]) {
          alert('Error: All fields in Shipping information are required.');

          return false;
        }
      }

      if (!/^[0-9]*$/.test(shippingDetails.mobileNumber)) {
        alert('Error: Mobile number provided in Shipping details in incorrect.');

        return false;
      }
    }

    return true;
  };

  handleCheckout = () => {
    const validForm = this.validateFormDetails();
    const { billingDetails, shippingDetails, showShippingDetails } = this.state;

    if (validForm) {
      this.props.addBillingDetails(billingDetails);
      if (showShippingDetails) {
        this.props.addShippingDetails(shippingDetails);
      }

      this.props.history.push(NavigationPaths.PAYMENTGATEWAYPAGE);
    }
  };

  shouldShowShippingDetails = () => {
    const books = get(this.props.cart, 'books', []);
    const giftCards = get(this.props.cart, 'giftCards', []);
    this.props.updateGiftCardFlag();

    if (books && books.length) {
      return this.setShowShippingDetails(true);
    }

    if (giftCards && giftCards.length) {
      for (let giftCard of giftCards) {
        if (giftCard.physical) {
          return this.setShowShippingDetails(true);
        }
      }
    }

    if (this.state.showShippingDetails) {
      this.setShowShippingDetails(false);
    }
  };

  render() {
    const { pageLoader, billingDetails, shippingDetails, showShippingDetails } = this.state;

    return (
      <div className="container-wrapper">
        <div className="container-wrapper-content">
          <div className="content-header d-flex align-items-center">
            <a href="https://www.wiseowllearning.io">
              <img
                src={require('../../assets/images/logos/owl-logo.png')}
                className="content-header-logo"
                alt="Wise Owl Learning"
              />
            </a>

            <img
              src={require('../../assets/images/logos/skip-logo.png')}
              className="content-header-logo content-header-logosquare"
              alt="Wise Owl Learning"
            />
          </div>

          <div className="content-body">
            <div className="content-section">
              <div className="content-body-header">
                <h2 className="content-body-title">Checkout</h2>
                <p className="content-body-description">
                  Tell us about yourself and about where do you want your products shipped.
                </p>
              </div>

              <div className="content-body-wrapper">
                <div className="content-form">
                  <h4 className="content-form-header">Billing Details</h4>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="firstNameBilling" className="input-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstNameBilling"
                          placeholder="Raj"
                          className="input-field"
                          value={billingDetails.firstName}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'firstName',
                              event.target.value,
                              'billingDetails'
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="lastNameBilling" className="input-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastNameBilling"
                          placeholder="Kapoor"
                          className="input-field"
                          value={billingDetails.lastName}
                          onChange={(event) =>
                            this.handleFieldUpdate('lastName', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="emailBilling" className="input-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="emailBilling"
                          placeholder="Raj@gmail.com"
                          className="input-field"
                          value={billingDetails.email}
                          onChange={(event) =>
                            this.handleFieldUpdate('email', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="mobileNumberBilling" className="input-label">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          maxLength={10}
                          id="mobileNumberBilling"
                          placeholder="9876543210"
                          className="input-field"
                          value={billingDetails.mobileNumber}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'mobileNumber',
                              event.target.value,
                              'billingDetails'
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="addressBilling" className="input-label">
                          Address
                        </label>
                        <input
                          type="text"
                          id="addressBilling"
                          placeholder="Gurgaon"
                          className="input-field"
                          value={billingDetails.address}
                          onChange={(event) =>
                            this.handleFieldUpdate('address', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="cityBilling" className="input-label">
                          City
                        </label>
                        <input
                          type="text"
                          id="cityBilling"
                          placeholder="Delhi"
                          className="input-field"
                          value={billingDetails.city}
                          onChange={(event) =>
                            this.handleFieldUpdate('city', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="stateBilling" className="input-label">
                          State
                        </label>
                        <input
                          type="text"
                          id="stateBilling"
                          placeholder="New Delhi"
                          className="input-field"
                          value={billingDetails.state}
                          onChange={(event) =>
                            this.handleFieldUpdate('state', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="countryBilling" className="input-label">
                          Country
                        </label>
                        <input
                          type="text"
                          id="countryBilling"
                          placeholder="India"
                          className="input-field"
                          value={billingDetails.country}
                          onChange={(event) =>
                            this.handleFieldUpdate('country', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="input-wrapper">
                        <label htmlFor="pincodeBilling" className="input-label">
                          Pincode
                        </label>
                        <input
                          type="text"
                          id="pincodeBilling"
                          placeholder="110011"
                          className="input-field"
                          value={billingDetails.pincode}
                          onChange={(event) =>
                            this.handleFieldUpdate('pincode', event.target.value, 'billingDetails')
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showShippingDetails && (
                <div className="content-body-wrapper">
                  <div className="content-form">
                    <h4 className="content-form-header">Shipping Details</h4>

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="firstNameShipping" className="input-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="firstNameShipping"
                            placeholder="Raj"
                            className="input-field"
                            value={shippingDetails.firstName}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                'firstName',
                                event.target.value,
                                'shippingDetails'
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="lastNameShipping" className="input-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="lastNameShipping"
                            placeholder="Kapoor"
                            className="input-field"
                            value={shippingDetails.lastName}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                'lastName',
                                event.target.value,
                                'shippingDetails'
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="mobileNumberShipping" className="input-label">
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            maxLength={10}
                            id="mobileNumberShipping"
                            placeholder="9876543210"
                            className="input-field"
                            value={shippingDetails.mobileNumber}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                'mobileNumber',
                                event.target.value,
                                'shippingDetails'
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="addressShipping" className="input-label">
                            Address
                          </label>
                          <input
                            type="text"
                            id="addressShipping"
                            placeholder="Gurgaon"
                            className="input-field"
                            value={shippingDetails.address}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                'address',
                                event.target.value,
                                'shippingDetails'
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="cityShipping" className="input-label">
                            City
                          </label>
                          <input
                            type="text"
                            id="cityShipping"
                            placeholder="Delhi"
                            className="input-field"
                            value={shippingDetails.city}
                            onChange={(event) =>
                              this.handleFieldUpdate('city', event.target.value, 'shippingDetails')
                            }
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="stateShipping" className="input-label">
                            State
                          </label>
                          <input
                            type="text"
                            id="stateShipping"
                            placeholder="New Delhi"
                            className="input-field"
                            value={shippingDetails.state}
                            onChange={(event) =>
                              this.handleFieldUpdate('state', event.target.value, 'shippingDetails')
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="countryShipping" className="input-label">
                            Country
                          </label>
                          <input
                            type="text"
                            id="countryShipping"
                            placeholder="India"
                            className="input-field"
                            value={shippingDetails.country}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                'country',
                                event.target.value,
                                'shippingDetails'
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-6">
                        <div className="input-wrapper">
                          <label htmlFor="pincodeShipping" className="input-label">
                            Pincode
                          </label>
                          <input
                            type="text"
                            id="pincodeShipping"
                            placeholder="110011"
                            className="input-field"
                            value={shippingDetails.pincode}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                'pincode',
                                event.target.value,
                                'shippingDetails'
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Cart handleNavigation={this.handleCheckout} checkoutButtonText={'Continue'} />
          </div>

          <div className="content-footer d-flex align-items-center justify-content-center">
            <p className="content-footer-copyright">
              &copy; Copyright 2020 - Wise Owl Learning Private Limited
              <br />
              All Rights Reserved
            </p>
          </div>
        </div>

        {pageLoader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  addBillingDetails,
  addShippingDetails,
  updateGiftCardFlag,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDetails);
