export const BASE_API_URL = 'https://liveshop.wiseowllearning.io';
// export const BASE_API_URL = 'http://localhost:3004';

export const GET_BOOKS = '/user/product/books';
export const CHECKOUT = '/user/product/order';
export const VERIFY_PAYMENT = '/user/product/order/verify';
export const FAIL_PAYMENT = '/user/product/order/fail';
export const RAZORPAY_SDK_URL = 'https://checkout.razorpay.com/v1/checkout.js';
export const CASHFREE_SDK_URL =
  'https://www.cashfree.com/assets/cashfree.sdk.v1.2.js';
export const CASHFREE_ENVIRONMENT = 'PROD'; //use TEST/PROD

// Start Redux Constants
export const ReduxConstants = {
  GETBOOKS: 'books/GET',
  ADDGIFTCARD: 'giftCard/ADD',
  REMOVEGIFTCARD: 'giftCard/REMOVE',
  UPDATEGIFTCARD: 'giftCard/UPDATE',
  UPDATEGIFTCARDFLAG: 'giftCard/CHECK',
  ADDBOOK: 'book/ADD',
  REMOVEBOOK: 'book/REMOVE',
  ADDEBOOK: 'ebook/ADD',
  REMOVEEBOOK: 'ebook/REMOVE',
  ADDBILLINGDETAILS: 'billing/ADD',
  ADDSHIPPINGDETAILS: 'shipping/ADD',
  ADDORDER: 'order/ADD',
  RESETCART: 'cart/RESET',
};

export const ProductTypes = {
  PACKAGE: 'PACKAGE',
  BOOK: 'BOOK',
  EBOOK: 'EBOOK',
};

export const NavigationPaths = {
  BILLINGDETAILSPAGE: '/checkout/details',
  PAYMENTDETAILSPAGE: '/checkout/payment',
  PAYMENTGATEWAYPAGE: '/checkout/gateway',
  PAYMENTSUCCESSPAGE: '/checkout/success',
  PAYMENTFAILPAGE: '/checkout/fail',
};

export const CashfreePaymentMethods = {
  UPI: 'UPI',
  CARD: 'CARD',
  WALLET: 'WALLET',
  NETBANKING: 'NB',
};

export const CashfreeNetBankingCodes = [
  // { name: 'TEST Bank', value: '3333' },
  { name: 'Allahabad Bank', value: '3001' },
  { name: 'Andhra Bank', value: '3002' },
  { name: 'Axis Bank', value: '3003' },
  { name: 'Bank of Baroda - Corporate', value: '3060' },
  { name: 'Bank of Baroda - Retail', value: '3005' },
  { name: 'Bank of India', value: '3006' },
  { name: 'Bank of Maharashtra', value: '3007' },
  { name: 'Canara Bank', value: '3009' },
  { name: 'Catholic Syrian Bank', value: '3010' },
  { name: 'Central Bank of India', value: '3011' },
  { name: 'City Union Bank', value: '3012' },
  { name: 'Corporation Bank', value: '3013' },
  { name: 'DBS Bank Ltd', value: '3017' },
  { name: 'DCB Bank - Corporate', value: '3062' },
  { name: 'DCB Bank - Personal', value: '3018' },
  { name: 'Deutsche Bank', value: '3016' },
  { name: 'Dhanlakshmi Bank', value: '3019' },
  { name: 'Federal Bank', value: '3020' },
  { name: 'HDFC Bank', value: '3021' },
  { name: 'HSBC Bank', value: '3069' },
  { name: 'ICICI Bank', value: '3022' },
  { name: 'IDBI Bank', value: '3023' },
  { name: 'IDFC Bank', value: '3024' },
  { name: 'Indian Bank', value: '3026' },
  { name: 'Indian Overseas Bank', value: '3027' },
  { name: 'IndusInd Bank', value: '3028' },
  { name: 'Jammu and Kashmir Bank', value: '3029' },
  { name: 'Karnataka Bank Ltd', value: '3030' },
  { name: 'Karur Vysya Bank', value: '3031' },
  { name: 'Kotak Mahindra Bank', value: '3032' },
  { name: 'Laxmi Vilas Bank', value: '3033' },
  { name: 'Oriental Bank of Commerce', value: '3035' },
  { name: 'Punjab & Sind Bank', value: '3037' },
  { name: 'Punjab National Bank - Corporate', value: '3065' },
  { name: 'Punjab National Bank - Retail', value: '3038' },
  { name: 'Saraswat Bank', value: '3040' },
  { name: 'South Indian Bank', value: '3042' },
  { name: 'Standard Chartered Bank', value: '3043' },
  { name: 'State Bank Of India', value: '3044' },
  { name: 'Tamilnad Mercantile Bank Ltd', value: '3052' },
  { name: 'UCO Bank', value: '3054' },
  { name: 'Union Bank of India', value: '3055' },
  { name: 'United Bank of India', value: '3056' },
  { name: 'Vijaya Bank', value: '3057' },
  { name: 'Yes Bank Ltd', value: '3058' },
];

export const CashfreeWalletCodes = [
  { name: 'FreeCharge', value: '4001' },
  { name: 'MobiKwik', value: '4002' },
  { name: 'OLA Money', value: '4003' },
  { name: 'Reliance Jio Money', value: '4004' },
  { name: 'Airtel money', value: '4006' },
  { name: 'Paytm', value: '4007' },
  { name: 'Amazon Pay', value: '4008' },
  { name: 'Phonepe', value: '4009' },
];

export const PaymentGateways = {
  CASHFREE: 'Cashfree',
  RAZORPAY: 'Razorpay',
};
