import { ReduxConstants } from '../constants/constants';

export const addGiftCard = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.ADDGIFTCARD,
    payload: dataPayload,
  });
};

export const removeGiftCard = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.REMOVEGIFTCARD,
    payload: dataPayload,
  });
};

export const updateGiftCard = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.UPDATEGIFTCARD,
    payload: dataPayload,
  });
};

export const addBook = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.ADDBOOK,
    payload: dataPayload,
  });
};

export const removeBook = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.REMOVEBOOK,
    payload: dataPayload,
  });
};

export const addEBook = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.ADDEBOOK,
    payload: dataPayload,
  });
};

export const removeEBook = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.REMOVEEBOOK,
    payload: dataPayload,
  });
};

export const addBillingDetails = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.ADDBILLINGDETAILS,
    payload: dataPayload,
  });
};

export const addShippingDetails = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.ADDSHIPPINGDETAILS,
    payload: dataPayload,
  });
};

export const updateGiftCardFlag = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.UPDATEGIFTCARDFLAG,
    payload: dataPayload,
  });
};

export const resetCart = () => (dispatch) => {
  dispatch({
    type: ReduxConstants.RESETCART,
  });
};
