import { ReduxConstants } from "../constants/constants";
import { get } from "lodash";

export default (state = { books: [], ebooks : [] }, action) => {
  switch (action.type) {
    case ReduxConstants.GETBOOKS:
      return {
        ...state,
        books: get(action.payload, "books", []),
        ebooks: get(action.payload, "ebooks", []),
      };

    default: {
      return state;
    }
  }
};
