import {
  checkout,
  verifyPayment,
  failPayment,
} from "../services/productService";

export const checkoutOrder = (payload) => {
  const {
    books,
    ebooks,
    gateway,
    giftCards,
    billingDetails,
    shippingDetails,
  } = payload;

  const dataPayload = {
    gateway,
    billingDetails,
    shippingDetails: {},
    products: [...books, ...giftCards, ...ebooks],
  };

  let setShippingDetails = false;
  if (books && books.length) {
    setShippingDetails = true;
  } else if (giftCards && giftCards.length) {
    for (let giftCard of giftCards) {
      if (giftCard.physical && !setShippingDetails) {
        setShippingDetails = true;
      }
    }
  }

  if (setShippingDetails) {
    dataPayload.shippingDetails = shippingDetails;
  }

  return checkout(dataPayload);
};

export const verifyOrder = (ecomOrderID, paymentGatewayID) => {
  const dataPayload = {
    ecomOrderID,
  };

  if (paymentGatewayID) {
    dataPayload.paymentGatewayID = paymentGatewayID;
  }

  return verifyPayment(dataPayload);
};

export const failOrder = (ecomOrderID) => {
  const dataPayload = {
    ecomOrderID,
  };

  return failPayment(dataPayload);
};
