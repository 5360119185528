import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { getBooksAction } from '../../actions/productActions';
import {
  addBook,
  addEBook,
  removeBook,
  removeEBook,
  addGiftCard,
  removeGiftCard,
} from '../../actions/cartActions';
import { ProductTypes, NavigationPaths } from '../../constants/constants';

import DataManager, { giftCards } from './dataManager';
import Cart from '../../components/Cart';
import PageLoader from '../../components/PageLoader';

class ProductShowcase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: false,
    };

    this.dataManager = new DataManager();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBooks();
  }

  getBooks = () => {
    this.setState({ pageLoader: true });
    this.dataManager
      .getBooks()
      .then((responseData) => {
        this.setState({ pageLoader: false });
        if (responseData.data) {
          this.props.getBooksAction(responseData.data);
        }
      })
      .catch((errorData) => {
        this.setState({ pageLoader: false });
      });
  };

  handleAddGiftCard = (giftCard) => {
    if (giftCard) {
      this.props.addGiftCard({
        ...giftCard,
        physical: false,
        type: ProductTypes.PACKAGE,
        value: giftCard.sellingPrice,
      });
    }
  };

  handleRemoveGiftCard = (sellingPrice) => {
    if (sellingPrice) {
      const giftCards = get(this.props.cart, 'giftCards', []);
      if (giftCards.length) {
        for (let index = giftCards.length - 1; index >= 0; index--) {
          if (giftCards[index].sellingPrice === sellingPrice) {
            return this.props.removeGiftCard({
              giftCardIndex: index,
            });
          }
        }
      }
    }
  };

  getGiftCardsCount = () => {
    const giftCards = get(this.props.cart, 'giftCards', []);

    let oneSubject = 0,
      twoSubject = 0,
      threeSubject = 0;

    if (giftCards.length) {
      for (let giftCard of giftCards) {
        if (giftCard.sellingPrice === 1500) {
          oneSubject++;
        } else if (giftCard.sellingPrice === 3000) {
          twoSubject++;
        } else if (giftCard.sellingPrice === 4000) {
          threeSubject++;
        }
      }
    }

    return { oneSubject, twoSubject, threeSubject };
  };

  handleAddBook = (book) => {
    if (book && book._id) {
      this.props.addBook({
        ...book,
        value: book._id,
        type: ProductTypes.BOOK,
      });
    }
  };

  handleRemoveBook = (bookIdentifier) => {
    if (bookIdentifier) {
      const books = get(this.props.cart, 'books', []);
      if (books.length) {
        for (let index = books.length - 1; index >= 0; index--) {
          if (books[index]._id === bookIdentifier) {
            return this.props.removeBook({
              bookIndex: index,
            });
          }
        }
      }
    }
  };

  handleAddEBook = (ebook) => {
    if (ebook && ebook._id) {
      this.props.addEBook({
        ...ebook,
        value: ebook._id,
        type: ProductTypes.EBOOK,
      });
    }
  };

  handleRemoveEBook = (ebookIdentifier) => {
    if (ebookIdentifier) {
      const ebooks = get(this.props.cart, 'ebooks', []);
      if (ebooks.length) {
        for (let index = ebooks.length - 1; index >= 0; index--) {
          if (ebooks[index]._id === ebookIdentifier) {
            return this.props.removeEBook({
              ebookIndex: index,
            });
          }
        }
      }
    }
  };

  getBooksCount = () => {
    const books = get(this.props.cart, 'books', []);
    const productBooks = get(this.props.products, 'books', []);
    let returnObj = {};

    if (productBooks.length) {
      for (let book of productBooks) {
        returnObj[book._id] = 0;
      }
    }

    if (books.length) {
      for (let book of books) {
        returnObj[book._id] += 1;
      }
    }

    return returnObj;
  };

  getEBooksCount = () => {
    const ebooks = get(this.props.cart, 'ebooks', []);
    const productEBooks = get(this.props.products, 'ebooks', []);
    let returnObj = {};

    if (productEBooks.length) {
      for (let ebook of productEBooks) {
        returnObj[ebook._id] = 0;
      }
    }

    if (ebooks.length) {
      for (let ebook of ebooks) {
        returnObj[ebook._id] += 1;
      }
    }

    return returnObj;
  };

  handleNavigation = () => {
    this.props.history.push(NavigationPaths.BILLINGDETAILSPAGE);
  };

  render() {
    const { pageLoader } = this.state;
    const books = get(this.props.products, 'books', []);
    const ebooks = get(this.props.products, 'ebooks', []);

    const giftCardsCount = this.getGiftCardsCount();
    const booksCount = this.getBooksCount();
    const ebooksCount = this.getEBooksCount();

    return (
      <div className="container-wrapper">
        <div className="content-header d-flex align-items-center">
          <a href="https://www.wiseowllearning.io">
            <img
              src={require('../../assets/images/logos/owl-logo.png')}
              className="content-header-logo"
              alt="Wise Owl Learning"
            />
          </a>

          <img
            src={require('../../assets/images/logos/skip-logo.png')}
            className="content-header-logo content-header-logosquare"
            alt="Wise Owl Learning"
          />
        </div>

        <div className="content-body">
          <div className="content-section">
            <div className="content-body-header">
              <h2 className="content-body-title">Gift Cards</h2>
              <p className="content-body-description">
                A unique gift that can be enjoyed year long: 1 year Subscription for Learning
                Programme SKIP. Gift card for any one, any two or all 3 subjects from Math, Science
                and English. This gift is as rewarding for the giver, watch the child explore and
                develop.
              </p>
            </div>

            <div className="content-body-wrapper">
              <div className="subscriptions-wrapper">
                {giftCards.map((giftCard, index) => {
                  return (
                    <div key={index} className="subscription-card">
                      <img
                        src={
                          giftCard.sellingPrice === 1500
                            ? require('../../assets/images/icons/gift-card-1500.png')
                            : giftCard.sellingPrice === 3000
                            ? require('../../assets/images/icons/gift-card-3000.png')
                            : require('../../assets/images/icons/gift-card-4000.png')
                        }
                        className="subscription-card-image"
                        alt={`Rs. ${giftCard.sellingPrice} Gift Card`}
                      />
                      <h3 className="subscription-card-title">{giftCard.title}</h3>
                      <h4 className="subscription-card-cost">
                        <small>Rs.</small> {giftCard.sellingPrice}{' '}
                        <span className="original-cost">
                          <strike>{giftCard.actualPrice}</strike>
                        </span>
                      </h4>
                      <div className="subscription-card-quantity d-flex align-items-center justify-content-start">
                        <button
                          type="button"
                          className="subscription-button"
                          disabled={giftCardsCount[giftCard.key] < 1}
                          onClick={() => this.handleRemoveGiftCard(giftCard.sellingPrice)}
                        >
                          -
                        </button>
                        <span className="subscription-value">{giftCardsCount[giftCard.key]}</span>
                        <button
                          type="button"
                          className="subscription-button"
                          onClick={() => this.handleAddGiftCard(giftCard)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {books && books.length !== 0 && (
            <div className="content-section">
              <div className="content-body-header">
                <h2 className="content-body-title">Books</h2>
                <p className="content-body-description">
                  Paperback high quality printed books, ready to use.
                  <br />
                  <br />
                  No-cost hands-on projects with things available at home. All 3 core subjects:
                  English, Science and Math. Investigate, explore and create the whole year round.
                </p>
              </div>

              <div className="content-body-wrapper">
                <div className="books-wrapper">
                  {books.map((book, index) => {
                    return (
                      <div key={index} className="book-card">
                        <div className="book-card-display">
                          <img
                            className="book-card-image"
                            alt="90 Home Based Projects with Owl for Class 5"
                            src={book.thumbnail}
                          />
                        </div>

                        <h3 className="book-card-title">{book.title || 'N/A'}</h3>
                        <p className="book-card-description">{book.description || 'N/A'}</p>

                        <h4 className="book-card-cost">
                          <small>Rs.</small> {get(book, 'prices.INR', 'N/A')}
                        </h4>

                        <div className="book-card-quantity d-flex align-items-center justify-content-start">
                          <button
                            type="button"
                            className="book-button"
                            disabled={booksCount[book._id] < 1}
                            onClick={() => this.handleRemoveBook(book._id)}
                          >
                            -
                          </button>
                          <span className="book-value">{booksCount[book._id]}</span>
                          <button
                            type="button"
                            className="book-button"
                            onClick={() => this.handleAddBook(book)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {ebooks && ebooks.length !== 0 && (
            <div className="content-section">
              <div className="content-body-header">
                <h2 className="content-body-title">E-Books</h2>
                <p className="content-body-description">
                  PDF files delivered instantly by mail.
                  <br />
                  <br />
                  No-cost hands-on projects with things available at home. All 3 core subjects:
                  English, Science and Math. Investigate, explore and create the whole year round.
                </p>
              </div>

              <div className="content-body-wrapper">
                <div className="books-wrapper">
                  {ebooks.map((ebook, index) => {
                    return (
                      <div key={index} className="book-card">
                        <div className="book-card-display">
                          <img
                            className="book-card-image"
                            alt="90 Home Based Projects with Owl for Class 5"
                            src={ebook.thumbnail}
                          />
                        </div>

                        <h3 className="book-card-title">{ebook.title || 'N/A'}</h3>
                        <p className="book-card-description">{ebook.description || 'N/A'}</p>

                        <h4 className="book-card-cost">
                          <small>Rs.</small> {get(ebook, 'prices.INR', 'N/A')}
                        </h4>

                        <div className="book-card-quantity d-flex align-items-center justify-content-start">
                          <button
                            type="button"
                            className="book-button"
                            disabled={ebooksCount[ebook._id] < 1}
                            onClick={() => this.handleRemoveEBook(ebook._id)}
                          >
                            -
                          </button>
                          <span className="book-value">{ebooksCount[ebook._id]}</span>
                          <button
                            type="button"
                            className="book-button"
                            onClick={() => this.handleAddEBook(ebook)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <Cart handleNavigation={this.handleNavigation} checkoutButtonText={'Continue'} />

        <div className="content-footer d-flex align-items-center justify-content-center">
          <p className="content-footer-copyright">
            &copy; Copyright 2020 - Wise Owl Learning Private Limited
            <br />
            All Rights Reserved
          </p>
        </div>

        {pageLoader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.products,
  cart: state.cart,
});

const mapDispatchToProps = {
  addBook,
  addEBook,
  removeBook,
  removeEBook,
  addGiftCard,
  getBooksAction,
  removeGiftCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductShowcase);
